import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import lottie from 'lottie-web'
import * as logoData from '~utils/logoData.json'

const AnimatedLogo = ({ className }) => {

	const [animation, setAnimation] = useState()
	const containerRef = useRef()

	useEffect(() => {
		if(containerRef?.current){
			const anim = lottie.loadAnimation({
				container: containerRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: true,
				animationData: logoData
			})
			setAnimation(anim)
		}
	}, [containerRef])
	return (
		<Logo ref={containerRef} className={className}/>
	)
}

const Logo = styled.div`
`


AnimatedLogo.propTypes = {
	className: PropTypes.string
}

export default AnimatedLogo