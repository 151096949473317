import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'

const ProjectGrid = ({ className, projects, onChange, onDirectionChange }) => {
	const [project, setProject] = useState(undefined)
	const [prevIndex, setPrevIndex] = useState(undefined)
	const [direction, setDirection] = useState(undefined)

	useEffect(() => {
		if(onChange){
			onChange(project)
		}
		if ((project || project === 0) && (prevIndex || prevIndex === 0)) {
			if (project > prevIndex) {
				setDirection('down')
			} else {
				setDirection('up')
			}
		} else if (!project && (prevIndex + 1 === projects.length)){
			setDirection('up')
		} else if (!project && (prevIndex === 0)) {
			setDirection('down')
		}
		setPrevIndex(project)
	}, [project])
	
	useEffect(() => {
		if(onDirectionChange){
			onDirectionChange(direction)
		}
	}, [direction])

	return (
		<Wrap className={className}>
			<ProjectsHeading>Recent projects</ProjectsHeading>
			<Projects>
				{projects?.map((project, i) => (
					<Project 
						key={project?.title}
						onMouseEnter={() => setProject(i)} 
						onMouseLeave={()=> setProject(undefined)}
					>
						<ProjectInner>
							<PName>{project?.title}</PName>
							<PTech>{project?.technology}</PTech>
							<PAtt>{project?.attribution}</PAtt>
						</ProjectInner>
					</Project>
				))}
			</Projects>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin-bottom: 13.1rem;
`
const ProjectsHeading = styled.div`
	grid-column: span 12;
	margin-bottom: 0.65rem;
`
const Projects = styled.div`
	grid-column: span 12;
	border-top: 1px solid var(--black);
`
const Project = styled.button`
	border-bottom: 1px solid var(--black);
	padding: 0.26rem 0;
	display: block;
	width: 100%;
	${mobile}{
		padding: 0.6rem 0
	}
`
const ProjectInner = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 24.7% 1fr max-content;
	text-align-last: left;
	${mobile}{
		grid-template-columns: repeat(2, 1fr);
	}
`
const PName = styled.div`
${mobile}{
	margin-bottom: 0.2rem;
}
`
const PTech = styled.div`
${mobile}{
	grid-row: 2;
}
`
const PAtt = styled.div`
	text-align: right;
	justify-self: flex-end;
`
ProjectGrid.propTypes = {
	className: PropTypes.string,
	projects: PropTypes.array,
	onChange: PropTypes.func,
	onDirectionChange: PropTypes.func,
}

export default ProjectGrid