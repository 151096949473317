import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import RichText from '~components/RichText'
import { Logo } from '~components/Svg'
import { mobile, tablet } from '~styles/global'
import { AnimatePresence, motion } from 'framer-motion'
import Image from '~components/Image'
import AnimatedLogo from '~components/AnimatedLogo'
import ProjectGrid from '~components/ProjectGrid'
import ProjectModal from '~components/ProjectModal'

const IndexPage = ({ data }) => {
	const { page, projects } = data

	const projectNodes = projects?.nodes
	const [project, setProject] = useState(undefined)
	const [fadeIn, setFadeIn] = useState(false)
	const [direction, setDirection] = useState(undefined)

	function lexographicalSort(a, b) {
		if (a['orderRank'] < b['orderRank']) {
			return -1
		}
		if (a['orderRank'] > b['orderRank']) {
			return 1
		}
		return 0
	}

	useEffect(() => {
		setTimeout(() => {
			setFadeIn(true)
		}, 700)
	}, [])

	projectNodes.sort(lexographicalSort)

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header show={fadeIn}>
				<MainText>
					<RichText content={page.mainText}/>
				</MainText>
				<Links >
					<LinkContainer>	
						<StyledA href={`mailto:${page.email}`}>
							{page.email}
						</StyledA>
					</LinkContainer>
					<LinkContainer>
						<StyledA href={`https://www.instagram.com/${page.instagram}`} target='_blank'>@{page.instagram}</StyledA>
					</LinkContainer>
				</Links>
			</Header>
			{/* <ProjectGrid projects={projectNodes} onChange={value => setProject(value)} onDirectionChange={value => setDirection(value)}/>	
			<ProjectModal projects={projectNodes} projectIndex={project} direction={direction}/>	 */}
			<Footer>
				<FooterContainer>
					<StyledLogo />
				</FooterContainer>
			</Footer>
			<DataViewer data={{...page, ...projects}} name="page"/>
		</>
	)
}

const Header = styled(Section)`
	margin-top: 1.2rem;
	margin-bottom: 4.3rem;
	opacity: ${props => props.show ? '1' : '0'};
	transition: transform 0.9s, opacity 0.8s;
	transform: ${props => props.show ? 'translateY(0%)' : 'translateY(10%)'};
`
const MainText = styled.div`
	grid-column: span 8;
	max-width: 680px;
	margin-top: 0.7rem;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 2.2rem;
	}
`
const Links = styled.div`
	grid-column: span 4;
	justify-self: end;
	${mobile}{
		grid-column: span 12;
		justify-self: start;
	}
`
const LinkContainer = styled.div`
	text-align: right;
	${mobile}{
		text-align: left;
		line-height: 1.8;
	}
`
const StyledA = styled.a`
	display: inline-block;
	position: relative;
	:after {
		content: '';
		position: absolute;
		top: calc(100% - 2px);
		width: 100%;
		height: 2px;
		left: 0;
		background-color: transparent;
		transition: background-color 0.3s;
	}
	&:hover{
		:after{
			background-color: var(--black);
		}
	}
	${mobile}{
		text-align: left;
	}
`

const Footer = styled(Section)`
	margin-bottom: 2rem;
	flex-grow: 1;
	display: flex;
	> div{
		width: 100%;
	}
`
const FooterContainer = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	${mobile}{
		flex-wrap: wrap;
	}
`
const StyledLogo = styled(AnimatedLogo)`
	max-width: 440px;
	${mobile}{
		max-width: 220px;
		margin-bottom: 0.6rem;	
	}
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
			title
			mainText: _rawMainText(resolveReferences: {maxDepth: 5})
			email
			instagram
			typesetAttribution
			seo{
				...seo
			}
    }
		projects: allSanityProject {
			nodes {
				title
				attribution
				technology
				orderRank
				media {
					mediaType
					image {
						...imageWithAlt
					}
					video {
						video {
							asset {
								_id
								url
							}
						}
					}
				}
			}
		}
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
