import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { AnimatePresence, motion } from 'framer-motion'
import Image from '~components/Image'

const Project = ({ className, active, direction, project }) => {

	console.log(direction)


	return (
		<AnimatePresence exitBeforeEnter initial={false}>
			{active &&
			<MediaOverlay
				key={project.title}
				className={className}
				transition={{ duration: 0.4 }}
				initial={{
					y: direction === 'up' ? 1000 : -1000
				}}
				animate={{
					zIndex: 1,
					y: 1
				}}
				exit={{
					y: direction === 'up' ? -1000 : 1000
				}}
			>
				<MediaContainer>
					<MediaInner>
						{project?.media?.mediaType === 'video' ? 
							<StyledVideo 
								src={project ? project?.media?.video?.video?.asset?.url : ''}
								playsInline 
								autoPlay 
								muted 
								loop 
							/>
							:
							<StyledImage cover image={project?.media?.image}/>
						}
					</MediaInner>
				</MediaContainer>
			</MediaOverlay>
			}
		</AnimatePresence>
	)
}


const MediaOverlay = styled(motion.div)`
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`
const MediaContainer = styled.div`
	width: 100%;
	max-width: 1130px;
	filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.25));
	@media (max-width: 1210px){
		max-width: calc(100% - 80px);
	}
	${mobile}{
		max-width: calc(100% - 40px);
	}
`
const MediaInner = styled.div`
	padding-bottom: 63.5%;
	position: relative;
	height: 0;
	overflow: hidden;
	border-radius: 10px;
`
const StyledVideo = styled.video`
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
`
const StyledImage = styled(Image)`
	position: absolute;
	width: 100%;
	height: 100%;
`

Project.propTypes = {
	className: PropTypes.string,
	selected: PropTypes.bool,	
	direction: PropTypes.string,
	project: PropTypes.object,
	active: PropTypes.bool,
}

export default Project